<template>
  <div>
    <b-modal
      v-model="modalData.status"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-footer
      title="Gönye CRM | Sürüm Güncellemesi"
      size="lg"
    >
      <div class="text-center font-weight-bold">
        <b-alert
          show
          variant="primary"
        >
          <div class="alert-body">
            <FeatherIcon
              icon="InfoIcon"
              size="24"
            />
            <div class="font-weight-bolder">
              Sürüm güncellemesi yayınlandı!
            </div>
            <div class="px-5">
              <p>
                {{ modalData.notes }}
              </p>
              <div class="font-small-3">
                <p>
                  Windows kullanıcıları: <kbd class="bg-light-primary">CTRL</kbd> + <kbd class="bg-light-primary">SHIFT</kbd> + <kbd class="bg-light-primary">R</kbd>
                </p>
                <p>
                  Mac kullanıcıları: <kbd class="bg-light-primary">CMD</kbd> + <kbd class="bg-light-primary">SHIFT</kbd> + <kbd class="bg-light-primary">R</kbd>
                </p>
              </div>
              <b-button
                variant="primary"
                pill
                class="my-1"
                @click="refreshPage"
              >
                Şimdi Yenile
              </b-button>
            </div>
          </div>
          <div
            class="bg-primary rounded-bottom font-small-2 text-white"
            style="padding: 5px 0"
          >
            Mevcut sürüm {{ modalData.current }} <FeatherIcon icon="ArrowRightIcon" /> Yeni Sürüm {{ modalData.version }}
            <br>
            {{ moment(modalData.updated).format('DD.MM.YYYY H:mm') }}
          </div>
        </b-alert>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { BAlert, BButton, BModal } from 'bootstrap-vue'

export default {
  components: {
    BAlert, BButton, BModal,
  },
  data() {
    return {
      modalData: {
        status: false,
        current: null,
        version: null,
        updated: null,
        notes: null,
      },
    }
  },
  mounted() {
    this.versionControl()
  },
  methods: {
    async versionControl() {
      const { title } = document
      const version = title.split(' - v1.0.')[1]
      this.$store.dispatch('app/checkVersion')
        .then(response => {
          if (response.version > version) {
            this.modalData.status = true
            this.modalData.current = `v1.0.${version}`
            this.modalData.version = `v1.0.${response.version}`
            this.modalData.updated = response.updated
            this.modalData.notes = response.notes
          }
        })
    },
    refreshPage() {
      const currentUrl = window.location.href
      window.location.href = `${currentUrl.split('?')[0]}?cache_buster=${new Date().getTime()}`
    },
  },
}
</script>
