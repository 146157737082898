import { $themeBreakpoints } from '@themeConfig'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    baseURL: 'https://api.gonyeotomotiv.com.tr',
    removeTitle: 'Uyarı!',
    removeMessage: 'Kayıt kalıcı olarak silinecektir! Devam etmek istiyor musunuz?',
    removeConfirm: 'Devam Et',
    removeCancel: 'Vazgeç',
    removeResultTitle: 'İşlem Başarılı!',
    removeResultMessage: 'Kayıt kalıcı olarak silinmiştir.!',
    removeResultClose: 'Kapat',
  },
  version: {
    version: null,
    updated: null,
    notes: null,
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    SET_VERSION(state, val) {
      state.version = val
    },
  },
  actions: {
    checkVersion({ commit }) {
      return axiosIns
        .get('/api/versionNo')
        .then(response => {
          commit('SET_VERSION', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
  },
}
